<template>
  <div>
    <v-row class="mt-0 pt-0 px-5" justify="center">
      <v-col cols="12" class="py-0">
        <v-layout justify-center>
          <h2 class="color-text-main pt-4">CONFIGURACIÓN DE CLIENTES</h2>
        </v-layout>
      </v-col>
    </v-row>
    <v-row class="mt-10" justify="center">
      <v-col cols="3">
        <p class="text-info mb-2 text-left">Nombre cliente</p>
        <v-autocomplete
          :rules="[(v) => !!v || 'Este campo es requerido']"
          v-model="form.clientId"
          item-value="id"
          item-text="name"
          :items="listClients"
          outlined
          label="Cliente *"
          color="#034f79"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <p class="text-info mb-2 text-left">Nombre campaña</p>
        <v-autocomplete
          :rules="[(v) => !!v || 'Este campo es requerido']"
          v-model="form.campaignId"
          item-value="id"
          item-text="name"
          :items="listCampaign"
          outlined
          label="Campaña *"
          color="#034f79"
        ></v-autocomplete>
      </v-col>
      <v-col cols="1">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mt-9"
              fab
              small
              dark
              color="#E95233"
              @click="modal.addClient = true"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Agregar configuración del cliente</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-divider class="mt-1 mb-7"></v-divider>

    <v-form ref="formF">
      <v-row justify="center">
        <v-col cols="3">
          <p class="text-left">Información del flujo</p>
          <v-autocomplete
            :rules="[(v) => !!v || 'Este campo es requerido']"
            v-model="form.actionId"
            item-value="id"
            item-text="name"
            :items="listActions"
            outlined
            label="Rol en la comisión *"
            color="#034f79"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="3" class="pt-13">
          <v-autocomplete
            :rules="[(v) => !!v || 'Este campo es requerido']"
            v-model="form.statusId"
            item-value="id"
            item-text="name"
            :items="listStatus"
            outlined
            label="Estado siguiente de la comisión *"
            color="#034f79"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="1" class="pt-13">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                class="mt-2"
                color="#034f79"
                @click="addStep()"
                dark
                fab
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span>Agregar configuración del cliente</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>

    <v-divider class="mt-7 mb-7"></v-divider>
    <v-row justify="center">
      <v-col cols="7">
        <v-alert v-if="steps.length == 0" dense text type="info">
          Selecciona un cliente o el cliente no tiene roles de comisión
        </v-alert>

        <v-row justify="center">
          <v-col v-for="(data, i) in steps" :key="data.order" cols="4">
            <v-card style="height: 210px" :key="data.order">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <p v-bind="attrs" v-on="on" class="s-order mb-0 pb-0 pt-3">
                    {{ data.order }}
                  </p>
                </template>
                <span>Nivel de aprobación en la comisión</span>
              </v-tooltip>
              <p>{{ data.actionName }}</p>
              <v-chip class="mb-3">
                {{ data.statusName }}
              </v-chip>
              <br />

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mt-1"
                    fab
                    icon
                    small
                    dark
                    color="red"
                    @click="deleteStep(i, data)"
                  >
                    <v-icon> mdi-trash-can-outline </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar este paso de la comisión</span>
              </v-tooltip>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="7">
        <v-layout justify-center>
          <v-btn color="#034f79" @click="save()" dark rounded>
            GUARDAR FLUJO
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>

    <v-dialog v-model="modal.addClient" max-width="400">
      <v-card>
        <v-card-title class="pt-5">
          <v-layout justify-center>
            <p class="color-text-main mb-0">Agregar una nueva campaña</p>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="text-info mb-2">
            Ten en cuenta que al momento de registrar una nueva campaña, esta no
            queda relacionada con <strong> MR CHISPA </strong> .
          </p>
          <p class="text-info mb-4"><strong> Nombre de la campaña </strong>.</p>
          <form ref="form1">
            <v-text-field
              :counter="1000"
              :rules="[
                (v) => v.length <= 1000 || 'Superó los 1000 caracteres.',
              ]"
              color="#034f79"
              v-model="form1.name"
              outlined
              label="Nombre de la campaña *"
            >
            </v-text-field>
            <v-text-field
              color="#034f79"
              v-model="form1.mr_chispa"
              outlined
              label="Número en mr chispa"
            >
            </v-text-field>
            <v-autocomplete
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="form1.client_id"
              item-value="id"
              item-text="name"
              :items="listClients"
              outlined
              label="Nombre del cliente *"
              color="#034f79"
            ></v-autocomplete>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="modal.addClient = false"
                color="#c1c1c1"
                dark
                rounded
                block
              >
                Cerrar
              </v-btn>
            </v-layout>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-layout justify-center>
              <v-btn
                :loading="loading.btn"
                class="mb-5"
                @click="saveCampaign()"
                color="#034f79"
                dark
                rounded
                block
              >
                Registrar
              </v-btn>
            </v-layout>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading.all">
      <v-progress-circular
        color="#e95233"
        width="8"
        indeterminate
        size="100"
      ></v-progress-circular
      ><br />
      <v-layout justify-center>
        <label class="mt-4" style="font-size: 20px">Cargando...</label>
      </v-layout>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import alert from "@/mixins/alert";
import color from "@/mixins/color";
import { STATUS, ACTIONS } from "./../../../utils/util";
export default {
  mixins: [alert, color],
  data() {
    return {
      ACTIONS: ACTIONS,
      STATUS: STATUS,
      form1: {
        name: "",
        client_id: null,
        mr_chispa: ""
      },
      modal: {
        addClient: false,
      },
      listStatus: [
        {
          name: "Aporbado",
          id: 4,
        },
        {
          name: "Aprobado coordinador",
          id: 17,
        },
      ],
      listActions: [],
      listClients: [],
      listCampaign: [],
      loading: {
        all: false,
        btn: false,
      },
      steps: [],
      form: {
        statusId: null,
        actionId: null,
        clientId: null,
        campaignId: null,
      },
      order: 0,
    };
  },
  watch: {
    "form.clientId": function (n) {
      if (n) {
        this.steps = [];
        this.getCampaignClient(n);
      }
    },
    "form.campaignId": function (n) {
      if (n) {
        this.order = 0;
        this.getClientById();
      }
    },
  },
  methods: {
    deleteStep(iS, data) {
      if (data.actionId == 4) {
        this.alert(
          "No se puede eliminar el paso de workforce, recuerda que este siempre debe ser el primero en el flujo de la comisión",
          "Oops...",
          "info"
        );
        return false;
      }
      this.steps.splice(iS, 1);
      this.order = 0;

      for (let i = 0; i < this.steps.length; i++) {
        this.steps[i].order = this.order;
        this.order++;
      }

      this.alert(
        "Recuerde dar clic en el botón de GUARDAR FLUJO para ver los cambios reflejados",
        "Oops...",
        "info"
      );
    },
    saveCampaign() {
      // Revisar estooo
      this.loading.all = true;
      Api.Workforce()
        .saveCampaign(this.form1)
        .then((res) => {
          if (res.data.cod == 0) {
            this.modal.addClient = false;
            this.alert(res.data.message, "Muy bien", "success");
            if(this.form.clientId) {
              this.getCampaignClient(this.form.clientId);
            }
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getClientById(id) {
      // Revisar estooo
      this.loading.all = true;
      Api.Workforce()
        .getClientById(this.form.clientId, this.form.campaignId) // cliente, campaña
        .then((res) => {
          var stepsL = res.data.data;
          this.steps = [];
          if (stepsL.length > 0) {
            for (let i = 0; i < stepsL.length; i++) {
              var data = {
                actionId: stepsL[i].action_id,
                actionName: stepsL[i].action.name,
                statusId: stepsL[i].status_id,
                statusName: stepsL[i].status.name,
                order: stepsL[i].order,
              };
              this.steps.push(data);
              if (i == stepsL.length - 1) {
                this.order = stepsL[i].order;
                this.order++;
              }
            }
          } else {
          }
        })
        .catch((error) => {
          this.steps = [];
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getStatus() {
      this.loading.all = true;
      Api.Workforce()
        .getStatus()
        .then((res) => {
          this.listStatus = res.data.data;
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getClient() {
      this.loading.all = true;
      Api.Workforce()
        .getClients()
        .then((res) => {
          this.listClients = res.data.data;
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getCampaignClient(id) {
      this.loading.all = true;
      Api.Workforce()
        .getCampaignClient(id)
        .then((res) => {
          this.listCampaign = res.data.data;
        })
        .catch((error) => {
          this.steps = [];
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getActions() {
      this.loading.all = true;
      Api.Workforce()
        .getActions()
        .then((res) => {
          this.listActions = res.data.data;
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    addStep() {
      var save = false;
      var found = false;
      if (this.$refs.formF.validate()) {
        // validar si el rol ya existe

        for (let i = 0; i < this.steps.length; i++) {
          if (this.steps[i].actionId == this.form.actionId.id) {
            found = true;
          }
        }

        if (found) {
          this.alert(
            "Ya existe un rol de la comisión para este cliente",
            "Oops...",
            "error"
          );
          return false;
        }

        if (this.steps.length == 0) {
          if (this.form.actionId.id != ACTIONS.workforce) {
            this.alert(
              "El primer paso del flujo debe ser un rol workforce, por favor asigne un rol de la comisión workforce y el estado correspondiente",
              "Oops...",
              "info"
            );
          } else {
            save = true;
          }
        } else {
          save = true;
        }
        if (save) {
          var data = {
            actionId: this.form.actionId.id,
            actionName: this.form.actionId.name,
            statusId: this.form.statusId.id,
            statusName: this.form.statusId.name,
            order: this.order,
          };
          this.order++;
          this.steps.push(data);
          this.form.actionId = null;
          this.form.statusId = null;
          this.$refs.formF.reset();
        }
      }
    },
    save() {
      this.loading.all = true;
      var data = {
        clientId: this.form.clientId,
        campaignId: this.form.campaignId,
        steps: this.steps,
      };
      Api.Workforce()
        .saveConfigClient(data)
        .then((res) => {
          console.log(res);
          this.alert(res.data.message, "Muy bien", "success");
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
  },
  mounted() {
    this.getClient();
    this.getActions();
    this.getStatus();
  },
};
</script>
<style scoped>
.s-order {
  font-size: 23px;
  color: #e35031;
  font-weight: bold;
}
</style>
