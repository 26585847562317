var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-0 pt-0 px-5",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('h2',{staticClass:"color-text-main pt-4"},[_vm._v("CONFIGURACIÓN DE CLIENTES")])])],1)],1),_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('p',{staticClass:"text-info mb-2 text-left"},[_vm._v("Nombre cliente")]),_c('v-autocomplete',{attrs:{"rules":[function (v) { return !!v || 'Este campo es requerido'; }],"item-value":"id","item-text":"name","items":_vm.listClients,"outlined":"","label":"Cliente *","color":"#034f79"},model:{value:(_vm.form.clientId),callback:function ($$v) {_vm.$set(_vm.form, "clientId", $$v)},expression:"form.clientId"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('p',{staticClass:"text-info mb-2 text-left"},[_vm._v("Nombre campaña")]),_c('v-autocomplete',{attrs:{"rules":[function (v) { return !!v || 'Este campo es requerido'; }],"item-value":"id","item-text":"name","items":_vm.listCampaign,"outlined":"","label":"Campaña *","color":"#034f79"},model:{value:(_vm.form.campaignId),callback:function ($$v) {_vm.$set(_vm.form, "campaignId", $$v)},expression:"form.campaignId"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-9",attrs:{"fab":"","small":"","dark":"","color":"#E95233"},on:{"click":function($event){_vm.modal.addClient = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Agregar configuración del cliente")])])],1)],1),_c('v-divider',{staticClass:"mt-1 mb-7"}),_c('v-form',{ref:"formF"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('p',{staticClass:"text-left"},[_vm._v("Información del flujo")]),_c('v-autocomplete',{attrs:{"rules":[function (v) { return !!v || 'Este campo es requerido'; }],"item-value":"id","item-text":"name","items":_vm.listActions,"outlined":"","label":"Rol en la comisión *","color":"#034f79","return-object":""},model:{value:(_vm.form.actionId),callback:function ($$v) {_vm.$set(_vm.form, "actionId", $$v)},expression:"form.actionId"}})],1),_c('v-col',{staticClass:"pt-13",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"rules":[function (v) { return !!v || 'Este campo es requerido'; }],"item-value":"id","item-text":"name","items":_vm.listStatus,"outlined":"","label":"Estado siguiente de la comisión *","color":"#034f79","return-object":""},model:{value:(_vm.form.statusId),callback:function ($$v) {_vm.$set(_vm.form, "statusId", $$v)},expression:"form.statusId"}})],1),_c('v-col',{staticClass:"pt-13",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"small":"","color":"#034f79","dark":"","fab":""},on:{"click":function($event){return _vm.addStep()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Agregar configuración del cliente")])])],1)],1)],1),_c('v-divider',{staticClass:"mt-7 mb-7"}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"7"}},[(_vm.steps.length == 0)?_c('v-alert',{attrs:{"dense":"","text":"","type":"info"}},[_vm._v(" Selecciona un cliente o el cliente no tiene roles de comisión ")]):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.steps),function(data,i){return _c('v-col',{key:data.order,attrs:{"cols":"4"}},[_c('v-card',{key:data.order,staticStyle:{"height":"210px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"s-order mb-0 pb-0 pt-3"},'p',attrs,false),on),[_vm._v(" "+_vm._s(data.order)+" ")])]}}],null,true)},[_c('span',[_vm._v("Nivel de aprobación en la comisión")])]),_c('p',[_vm._v(_vm._s(data.actionName))]),_c('v-chip',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(data.statusName)+" ")]),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","icon":"","small":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deleteStep(i, data)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar este paso de la comisión")])])],1)],1)}),1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{attrs:{"color":"#034f79","dark":"","rounded":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" GUARDAR FLUJO ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.modal.addClient),callback:function ($$v) {_vm.$set(_vm.modal, "addClient", $$v)},expression:"modal.addClient"}},[_c('v-card',[_c('v-card-title',{staticClass:"pt-5"},[_c('v-layout',{attrs:{"justify-center":""}},[_c('p',{staticClass:"color-text-main mb-0"},[_vm._v("Agregar una nueva campaña")])])],1),_c('v-card-text',[_c('p',{staticClass:"text-info mb-2"},[_vm._v(" Ten en cuenta que al momento de registrar una nueva campaña, esta no queda relacionada con "),_c('strong',[_vm._v(" MR CHISPA ")]),_vm._v(" . ")]),_c('p',{staticClass:"text-info mb-4"},[_c('strong',[_vm._v(" Nombre de la campaña ")]),_vm._v(".")]),_c('form',{ref:"form1"},[_c('v-text-field',{attrs:{"counter":1000,"rules":[
              function (v) { return v.length <= 1000 || 'Superó los 1000 caracteres.'; } ],"color":"#034f79","outlined":"","label":"Nombre de la campaña *"},model:{value:(_vm.form1.name),callback:function ($$v) {_vm.$set(_vm.form1, "name", $$v)},expression:"form1.name"}}),_c('v-text-field',{attrs:{"color":"#034f79","outlined":"","label":"Número en mr chispa"},model:{value:(_vm.form1.mr_chispa),callback:function ($$v) {_vm.$set(_vm.form1, "mr_chispa", $$v)},expression:"form1.mr_chispa"}}),_c('v-autocomplete',{attrs:{"rules":[function (v) { return !!v || 'Este campo es requerido'; }],"item-value":"id","item-text":"name","items":_vm.listClients,"outlined":"","label":"Nombre del cliente *","color":"#034f79"},model:{value:(_vm.form1.client_id),callback:function ($$v) {_vm.$set(_vm.form1, "client_id", $$v)},expression:"form1.client_id"}})],1)]),_c('v-card-actions',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"mb-5",attrs:{"loading":_vm.loading.btn,"color":"#c1c1c1","dark":"","rounded":"","block":""},on:{"click":function($event){_vm.modal.addClient = false}}},[_vm._v(" Cerrar ")])],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"mb-5",attrs:{"loading":_vm.loading.btn,"color":"#034f79","dark":"","rounded":"","block":""},on:{"click":function($event){return _vm.saveCampaign()}}},[_vm._v(" Registrar ")])],1)],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.all}},[_c('v-progress-circular',{attrs:{"color":"#e95233","width":"8","indeterminate":"","size":"100"}}),_c('br'),_c('v-layout',{attrs:{"justify-center":""}},[_c('label',{staticClass:"mt-4",staticStyle:{"font-size":"20px"}},[_vm._v("Cargando...")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }